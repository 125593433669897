import React, { useState, Fragment } from "react";
import { Logger } from "aws-amplify";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { replace } from "../../lib/transform_funcs";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import Tooltip from "@mui/material/Tooltip";
import { removeUndefined } from "../../lib/transform_funcs";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
var R = require("ramda");

// import {
//   Select,
//   MenuItem,
//   ListItemText,
//   InputLabel,
//   FormControl,
// } from "@mui/material";

const logger = new Logger("MrtToolbarButtonSearchReplace", "INFO");

// Utility function to flatten object keys into dot notation
const getObjectPaths = (obj: any, prefix = ""): string[] => {
  if (typeof obj !== "object" || obj === null) {
    return [prefix]; // Base case: primitive value
  }
  return Object.keys(obj).flatMap((key) =>
    getObjectPaths(obj[key], prefix ? `${prefix}.${key}` : key)
  );
};

const MrtToolbarButtonSearchReplace = <
  TData extends { id: string; [key: string]: any },
>({
  setScenarioChanged = () => {},
  setValidationErrors = () => {},
  validateFn = () => ({}),
  setData,
  data,
}: {
  setScenarioChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationErrors?: React.Dispatch<
    React.SetStateAction<Record<string, any>>
  >;
  validateFn?: (data: TData) => Record<string, any>;
  setData: React.Dispatch<React.SetStateAction<TData[]>>;
  data: TData[]; // Current dataset
}) => {
  const [open, setOpen] = useState(false);
  const [searchPattern, setSearchPattern] = useState("");
  const [replacementPattern, setReplacementPattern] = useState("");
  const [ignoreCase, setIgnoreCase] = useState(false);
  const [searchGlobal, setSearchGlobal] = useState(false);

  // New query context for filtering rows
  const [queryContext, setQueryContext] = useState(""); // Regular expression for filtering
  const [queryContextField, setQueryContextField] = useState(""); // Field to apply the query context

  // Extract column paths (including nested objects)
  const columnPaths = getObjectPaths(data[0] || {});
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnPaths); // Default all columns selected
  const [selectAllColumns, setSelectAllColumns] = useState(true);

  const handleClickOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setSearchPattern("");
    setReplacementPattern("");
    setIgnoreCase(false);
    setSearchGlobal(false);
    setQueryContext("");
    setQueryContextField("");
    setSelectAllColumns(true);
    setSelectedColumns(columnPaths); // Reset selected columns
    setOpen(false);
  };

  const mutateRows = React.useCallback(
    ({
      rows,
      searchPattern,
      replacementPattern,
      searchGlobal,
      ignoreCase,
      columns,
      queryContext,
      queryContextField,
    }) => {
      if (!searchPattern) return rows;
      logger.info(
        `mutateRows - searchPattern: ${searchPattern}, replacementPattern: ${replacementPattern}, searchGlobal: ${searchGlobal}, ignoreCase: ${ignoreCase}, columns: ${columns}, queryContext: ${queryContext}, queryContextField: ${queryContextField}`,
        rows
      );
      const queryRegExp = queryContext
        ? new RegExp(queryContext, ignoreCase ? "i" : "")
        : null;

      return [...rows].map((row) => {
        logger.info("row", row);
        // Filter rows by query context if specified
        if (queryRegExp && queryContextField) {
          const contextValue = queryContextField
            .split(".")
            .reduce((obj, key) => obj?.[key], row);
          logger.info(`contextValue: ${contextValue}`);
          if (!contextValue || !queryRegExp.test(String(contextValue))) {
            return row; // Skip row if it doesn't match the query context
          }
          logger.info(`Final contextValue: ${contextValue}`);
        }

        let newRow = R.clone(row);
        // let newRow = { ...row };
        logger.info("this newRow", newRow);
        columns.forEach((col) => {
          const colValue = col.split(".").reduce((obj, key) => obj?.[key], row);
          logger.info(`col: ${col}, colValue: ${colValue}`);
          if (colValue !== undefined && colValue !== null) {
            const newColValue = replace(
              colValue,
              searchPattern,
              replacementPattern,
              searchGlobal,
              ignoreCase
            );
            col.split(".").reduce((obj, key, i, arr) => {
              if (i === arr.length - 1) {
                obj[key] = newColValue;
              }
              return obj[key];
              //  || (obj[key] = {}); //TODO: check if this is needed
            }, newRow);
          }
        });
        return newRow;
      });
    },
    []
  );

  const handleReplace = React.useCallback(() => {
    logger.info("currentRows", data);
    let newRows = mutateRows({
      rows: data,
      searchPattern,
      replacementPattern,
      searchGlobal,
      ignoreCase,
      columns: selectedColumns,
      queryContext,
      queryContextField,
    });
    logger.info("newRows", newRows);
    setData(newRows);

    setScenarioChanged(true);
    // Validate all rows and update validation errors
    setValidationErrors(
      removeUndefined(
        newRows.reduce(
          (acc, row) => ({
            ...acc,
            ...validateFn(row),
          }),
          {}
        )
      )
    );
  }, [
    data,
    setData,
    setValidationErrors,
    validateFn,
    setScenarioChanged,
    mutateRows,
    searchPattern,
    replacementPattern,
    searchGlobal,
    ignoreCase,
    selectedColumns,
    queryContext,
    queryContextField,
  ]);

  const handleColumnSelectChange = (event) => {
    logger.info("handleColumnSelectChange", event.target.value);
    // const value = event.target.value;
    const columns = event.target.value;
    setSelectAllColumns(false);
    setSelectedColumns(columns.filter((col) => col !== ""));
    // // Check if the "Select/Deselect All Columns" option is selected
    // if (value.includes("all")) {
    //   handleSelectAllChange();
    // } else
    //   setSelectedColumns((prev) =>
    //     prev.includes(column)
    //       ? prev.filter((col) => col !== column)
    //       : [...prev, column]
    //   );
  };

  const handleSelectAllChange = () => {
    logger.info("handleSelectAllChange", selectAllColumns);
    if (selectAllColumns) {
      setSelectedColumns([]); // Deselect all
    } else {
      setSelectedColumns(columnPaths); // Select all
    }
    setSelectAllColumns(!selectAllColumns);
  };

  return (
    <Fragment>
      <Tooltip title="Replace value in selected columns using regular expression">
        <Button
          variant="contained"
          size="small"
          startIcon={<FindReplaceIcon />}
          onClick={handleClickOpen}
        >
          Replace
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} disableEnforceFocus fullWidth>
        <DialogTitle>Search & Replace</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Search and replace using a regex pattern (ECMAscript flavor). For
            help with regex, try{" "}
            <Link href="https://regex101.com/" rel="noreferrer" target="_blank">
              https://regex101.com/
            </Link>
          </DialogContentText>
          <Stack direction="column" spacing={2}>
            <FormGroup row={true} sx={{ mt: 3 }}>
              <TextField
                autoFocus
                // margin="dense"
                id="search-pattern"
                label="Search pattern"
                type="search"
                fullWidth
                variant="standard"
                value={searchPattern}
                onChange={(e) => setSearchPattern(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ignoreCase}
                    onChange={() => setIgnoreCase(!ignoreCase)}
                  />
                }
                label="Ignore Case"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={searchGlobal}
                    onChange={() => setSearchGlobal(!searchGlobal)}
                  />
                }
                label="Global"
              />
              <TextField
                // margin="dense"
                id="replacement-value"
                label="Replacement value"
                type="search"
                fullWidth
                variant="standard"
                value={replacementPattern}
                onChange={(e) => setReplacementPattern(e.target.value)}
              />
            </FormGroup>

            <FormControl id="column-select-form" fullWidth sx={{ mt: 3 }}>
              <InputLabel id="column-select-label">
                Select Columns for Search/Replace
              </InputLabel>
              <Select
                label="Select Columns for Search/Replace"
                labelId="column-select-label"
                multiple
                value={selectedColumns}
                onChange={handleColumnSelectChange}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="">
                  <Checkbox
                    checked={selectAllColumns}
                    onChange={handleSelectAllChange}
                  />
                  <ListItemText primary="Select/Deselect All Columns" />
                </MenuItem>
                <Divider component="li" />
                {columnPaths.map((column) => (
                  <MenuItem key={column} value={column}>
                    <Checkbox checked={selectedColumns.includes(column)} />
                    <ListItemText primary={column} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" spacing={2}>
              <TextField
                margin="dense"
                id="query-context-field"
                label="Field for Query Filter"
                type="search"
                fullWidth
                variant="standard"
                value={queryContextField}
                onChange={(e) => setQueryContextField(e.target.value)}
                helperText="Specify the field to apply the query filter (e.g., 'integration_type')."
              />
              <TextField
                margin="dense"
                id="query-context"
                label="Query Filter (Regex)"
                type="search"
                fullWidth
                variant="standard"
                value={queryContext}
                onChange={(e) => setQueryContext(e.target.value)}
                helperText="Filter rows using a regex before applying replacement."
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleReplace}>
            Replace
          </Button>

          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default MrtToolbarButtonSearchReplace;