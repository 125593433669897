import { useState } from "react";
import {
  Typography,
  Box,
  Tooltip,
  Chip,
  IconButton,
  Badge,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

type Props = {
  item: any;
};

function valueCountLabel(count) {
  if (count === 0) {
    return "no values";
  }
  if (count > 99) {
    return `More than 99 values; click to show all`;
  }
  return `${count} values; click ellipses to show all`;
}

export default function AlertTagSummary({ item: tag }: Props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 1,
        width: "100%",
        flexWrap: "wrap",
        marginBottom: 1,
        padding: 1,
        border: 1,
        borderColor: "primary.main",
        borderRadius: 1,
        position: "relative",
        paddingRight: 6,
        paddingLeft: 1,
        paddingBottom: 1.5,
        maxWidth: "100%", // Limits width to screen size
        overflow: "hidden", // Prevents overflow
      }}
    >
      <Typography sx={{ mr: 2, fontWeight: 700 }}>{tag.name}:</Typography>
      {Array.isArray(tag.value) ? (
        expanded ? (
          tag.value.map((value) => (
            <Tooltip key={value} title={value} arrow>
              <Chip
                label={value}
                sx={{
                  maxWidth: "180px", // Limits individual Chip width
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </Tooltip>
          ))
        ) : (
          tag.value.slice(0, 5).map((value) => (
            <Tooltip key={value} title={value} arrow>
              <Chip
                label={value}
                sx={{
                  maxWidth: "180px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </Tooltip>
          ))
        )
      ) : (
        <Tooltip title={tag.value} arrow>
          <Chip label={tag.value} />
        </Tooltip>
      )}
      {Array.isArray(tag.value) && tag.value.length > 5 && (
        <Tooltip
          title={expanded ? "Show first 5 values" : "Show all values"}
          placement="left"
          arrow
        >
          <IconButton
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setExpanded(!expanded);
            }}
            sx={{
              position: "absolute",
              right: 4,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Badge badgeContent={tag.value.length} color="secondary" max={99}>
              <MoreHorizIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
