import React, { useCallback, useEffect, useState } from "react";
import { Logger } from "aws-amplify";
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
  type MRT_RowSelectionState,
} from "material-react-table";

// mui components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAppState from "../../store/appState";
import useServerStateMutations, {
  useDemoConfig,
  useScenarioStore,
  useOrgScenarios,
} from "../../store/serverState";
import type { Scenario, OrgScenario } from "../../types/API";
import { getDefaultMRTOptions } from "../Tables/MRT_Factories";

// Global functions, constants and variables
const logger = new Logger("ScenarioStore", "INFO");
const scenarioColumnHelper = createMRTColumnHelper<Scenario>();
const defaultScenariotMRTOptions = getDefaultMRTOptions<Scenario>();

const scenarioColumns = [
  scenarioColumnHelper.accessor((row) => row["name"], {
    header: "Name",
    id: "name",
  }),
  scenarioColumnHelper.accessor((row) => row["description"], {
    header: "Description",
    id: "description",
  }),
  scenarioColumnHelper.accessor((row) => row["owners"].join(","), {
    header: "Owners",
    id: "owners",
  }),
  scenarioColumnHelper.accessor(
    (row) => new Date(row["updatedAt"]).toLocaleString(),
    {
      header: "Updated",
      id: "updatedAt",
    }
  ),
];

const ScenarioStore = ({ openStoreDialog, setOpenStoreDialog }) => {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const { removeOrgScenarios, addOrgScenarios } = useServerStateMutations();
  // Correctly typing removeOrgScenarios mutation
  const removeOrgScenariosWithTypes = useCallback(
    (orgScenarios: OrgScenario[]) => {
      return removeOrgScenarios.mutateAsync(orgScenarios as any); // Cast to 'any' for mutateAsync to accept the argument
    },
    [removeOrgScenarios]
  );

  // Correctly typing addOrgScenarios mutation
  type AddOrgScenariosInput = {
    orgID: string;
    scenarioIDs: string[];
    owners: string[];
  };

  const addOrgScenariosWithTypes = useCallback(
    (input: AddOrgScenariosInput) => {
      return addOrgScenarios.mutateAsync(input as any); // Cast to 'any' for mutateAsync to accept the argument
    },
    [addOrgScenarios]
  );

  const {
    isLoading: isScenerioDataLoading,
    error: scenarioDataError,
    data: scenarioData,
    isFetching: isScenarioDataFetching,
    isInitialLoading,
  } = useScenarioStore();

  const [scenarioRowsSelection, setScenarioRowsSelection] =
    useState<MRT_RowSelectionState>(() =>
      orgScenerios.reduce((acc, orgScenario) => {
        acc[orgScenario.scenarioID] = true;
        return acc;
      }, {})
    );

  const handleSubmit = useCallback(
    async function handleSubmit() {
      // compare the scenario IDs (keys) of the selected rows with the scenario IDs of the orgScenarios, returning a list of scenario IDs to remove
      const orgScenariosToRemove = orgScenerios.filter(
        (orgScenario) => !scenarioRowsSelection[orgScenario.scenarioID]
      );
      logger.info("orgScenariosToRemove", orgScenariosToRemove);

      // compare the scenario IDs (keys) of the selected rows with the scenario IDs of the orgScenarios, returning a list of scenario IDs to add
      const scenarioIDsToAdd = Object.keys(scenarioRowsSelection).filter(
        (scenarioID) =>
          scenarioRowsSelection[scenarioID] &&
          !orgScenerios.some(
            (orgScenario) => orgScenario.scenarioID === scenarioID
          )
      );
      logger.info("scenarioIDsToAdd", scenarioIDsToAdd);

      removeOrgScenariosWithTypes(orgScenariosToRemove);
      addOrgScenariosWithTypes({
        orgID: demoConfig?.id!,
        scenarioIDs: scenarioIDsToAdd,
        owners: demoConfig?.owners!,
      });
      setOpenStoreDialog(false);
    },
    [
      addOrgScenariosWithTypes,
      removeOrgScenariosWithTypes,
      setOpenStoreDialog,
      scenarioRowsSelection,
      orgScenerios,
      demoConfig?.id,
      demoConfig?.owners,
    ]
  );

  const scenariosTable = useMaterialReactTable({
    ...defaultScenariotMRTOptions,
    columns: scenarioColumns,
    data: scenarioData,
    getRowId: (row) => `${row.id}`,
    enableEditing: false,
    enableRowPinning: true,
    rowPinningDisplayMode: "select-top",
    keepPinnedRows: true,
    onRowSelectionChange: setScenarioRowsSelection,

    initialState: {
      ...defaultScenariotMRTOptions.initialState,
      sorting: [{ id: "name", desc: false }],
      columnVisibility: { id: false },
      showColumnFilters: true,
      density: "compact",
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: isScenerioDataLoading,
      showProgressBars: isScenarioDataFetching,
      rowSelection: scenarioRowsSelection,
    },
  });

  useEffect(() => {
    if (isInitialLoading || isScenerioDataLoading) return;
    if (openStoreDialog)
      setScenarioRowsSelection(
        orgScenerios.reduce((acc, orgScenario) => {
          acc[orgScenario.scenarioID] = true;
          return acc;
        }, {})
      );
    scenariosTable.setRowPinning({
      top: orgScenerios.map((orgScenario) => orgScenario.scenarioID),
    });
  }, [
    openStoreDialog,
    orgScenerios,
    isInitialLoading,
    isScenerioDataLoading,
    scenariosTable,
  ]);

  if (scenarioDataError)
    return "An error has occurred: " + scenarioDataError.message;

  return (
    <Dialog
      open={openStoreDialog}
      onClose={() => setOpenStoreDialog(false)}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>DemoSim Scenario Store</DialogTitle>
      <DialogContent
        sx={{
          width: "auto",
        }}
      >
        <DialogContentText>
          DemoSim scenarios available to you.
        </DialogContentText>
        <MaterialReactTable table={scenariosTable} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenStoreDialog(false)}
          variant="contained"
          color="error"
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="success">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioStore;
