import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Logger } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
  type MRT_ColumnDef,
} from "material-react-table";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";

// MUI components
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

// Custom components
import OrgSelector from "../../components/OrgSelector/OrgSelector.js";
import ScenarioCreator from "../../components/ScenarioCreator/ScenarioCreator";
import IncidentSearch from "../../components/IncidentSearch/IncidentSearch";
import HorizontalLinearStepper, {
  WizardStepProps,
} from "../../components/WizardStepper/HorizontalLinearStepper";
import MrtToolbarButtonExport from "../../components/Tables/MRT_ToolbarButton_Export";
import MrtToolbarButtonSearchReplace from "../../components/Tables/MRT_ToolbarButton_SearchReplace";
import FreeSoloChips from "../../components/Inputs/FreeSoloChips";

// Custom hooks and functions
import useAppState from "../../store/appState";
import { useDemoConfig } from "../../store/serverState";
import { getBpIncidentEvents } from "../../store/graphql-functions";
import type { Event } from "../../types/API";
import { getDefaultMRTOptions } from "../../components/Tables/MRT_Factories";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

// Global functions, constants and variables
const logger = new Logger("ScenarioLab", "INFO");
const eventColumnHelper = createMRTColumnHelper<Event>();
const defaultEventMRTOptions = getDefaultMRTOptions<Event>();

export default function ScenarioLab() {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);

  // Local state
  const [eventData, setEventData] = useState([]);
  const [selectedIncidents, setSelectedIncidents] = useState([]);
  const [bpIncidentEvents, setBpIncidentEvents] = useState([]);
  // search or specify
  const [incidentSelectorChoice, setIncidentSelectorChoice] = useState<
    "search" | "specify"
  >("search");
  const [isBpIncidentEventsLoading, setIsBpIncidentEventsLoading] =
    useState(false);
  // define a function using getBpIncidentEvents to get incident events
  const getIncidentEvents = useCallback(
    async function getIncidentEvents() {
      logger.info("getIncidentEvents called", selectedIncidents);

      try {
        const events = await getBpIncidentEvents({
          region: demoConfig.region,
          access_token: demoConfig.api_key,
          incidentIDs: selectedIncidents.map((incident: any) => incident.id),
        });
        logger.info("getIncidentEvents events:", events);
        return events;
      } catch (error) {
        logger.error("getIncidentEvents error:", error);
      }
    },
    [demoConfig.api_key, demoConfig.region, selectedIncidents]
  );

  const integrationTypes = React.useMemo(
    () => demoConfig.integrations.map((i: any) => i.integration_type),
    [demoConfig.integrations]
  );

  const eventColumns = useMemo<MRT_ColumnDef<Event>[]>(() => {
    return eventData.reduce(
      (headers, event) => {
        let newHeaders = [];
        if (event.event_type === "ALERT" || event.event_type === "ITAG") {
          let accessorIDs = headers.map((h) => h?.id);
          let newTags = Object.keys(event?.tags).filter(
            (tag) => !accessorIDs.includes(tag)
          );
          newTags.forEach((tag) => {
            let tagRenderer = eventColumnHelper.accessor(
              (row) => row.tags && row.tags[tag],
              {
                header: tag,
                id: tag,
              }
            );
            // if tag is a number, then add a Cell renderer to render the number
            if (typeof event[tag] === "number") {
              tagRenderer.Cell = ({ cell }) => (
                <span>${cell.getValue<number>().toLocaleString()}</span>
              );
            }
            //@ts-ignore
            newHeaders.push(tagRenderer);
          });
        }
        return [...headers, ...newHeaders];
      },
      [
        eventColumnHelper.accessor((row) => row["event_type"], {
          header: "Event Type",
          id: "event_type",
          size: 160,
        }),
        eventColumnHelper.accessor((row) => Number(row["_offset"]), {
          header: "Offset",
          id: "_offset",
          size: 130,
        }),
        eventColumnHelper.accessor((row) => Number(row["seconds"]), {
          header: "Seconds",
          id: "seconds",
          size: 150,
        }),
        eventColumnHelper.accessor((row) => row["integration_type"], {
          header: "Integration Type",
          id: "integration_type",
          size: 190,
        }),
        eventColumnHelper.accessor((row) => row["primary_property"], {
          header: "Primary Property",
          id: "primary_property",
          size: 200,
        }),
        eventColumnHelper.accessor((row) => row["secondary_property"], {
          header: "Secondary Property",
          id: "secondary_property",
          size: 210,
        }),
        eventColumnHelper.accessor((row) => row.tags && row.tags["status"], {
          header: "Status",
          id: "status",
          size: 130,
        }),
      ]
    );
  }, [eventData]);

  function eventsToRows(events) {
    return events.map((event) => {
      return {
        id: uuidv4(),
        event_type: event.event_type,
        _offset: Number(event._offset),
        seconds: Number(event.seconds),
        integration_type: event.integration_type,
        primary_property: event.primary_property,
        secondary_property: event.secondary_property,
        tags: JSON.parse(event.tags),
      };
    });
  }

  const handleSelectedIncidentsChanged = useCallback(async () => {
    if (selectedIncidents.length > 0) {
      setIsBpIncidentEventsLoading(true);
      try {
        const events = await getIncidentEvents();
        setBpIncidentEvents(events);
      } catch (error) {
        logger.error("getIncidentEvents error:", error);
      } finally {
        setIsBpIncidentEventsLoading(false);
      }
    } else {
      setBpIncidentEvents([]);
    }
  }, [selectedIncidents, getIncidentEvents]);

  useEffect(() => {
    handleSelectedIncidentsChanged();
  }, [selectedIncidents]);

  useEffect(() => {
    if (
      Array.isArray(bpIncidentEvents) &&
      bpIncidentEvents.length > 0 &&
      selectedIncidents.length > 0
    ) {
      let formattedEvents = bpIncidentEvents.map((event: any) => {
        const {
          event_type,
          _offset,
          seconds,
          integration_type,
          primary_property,
          secondary_property,
          ...eventTags
        } = event;
        return {
          event_type,
          _offset: Number(_offset),
          seconds: Number(seconds) || 0,
          integration_type,
          primary_property,
          secondary_property,
          tags: JSON.stringify({ ...eventTags }),
        };
      });
      setEventData(eventsToRows(formattedEvents));
    } else {
      setEventData([]);
    }
  }, [bpIncidentEvents, selectedIncidents]);

  const eventsTable = useMaterialReactTable({
    ...defaultEventMRTOptions,
    columns: eventColumns,
    data: eventData,
    enableEditing: false,
    initialState: {
      ...defaultEventMRTOptions.initialState,
      sorting: [{ id: "_offset", desc: false }],
      columnVisibility: { id: false },
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: isBpIncidentEventsLoading,
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        // header: "Change Account Settings", //change header text
        size: 100, //make actions column wider
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <MrtToolbarButtonSearchReplace
          data={eventData}
          setData={setEventData}
          validateFn={(data) => data}
          setValidationErrors={() => {}}
          setScenarioChanged={() => {}}
        />
        <MrtToolbarButtonExport table={table} />
      </>
    ),
  });

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Scenario Lab retrieves incidents from a BigPanda Org to create a DemoSim scenario.

      To perform the following steps you need a Demo Config record with a BigPanda Org User API Key.

      If you've already created a Demo Config record, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        component: <OrgSelector />,
        gate: Boolean(integrationTypes.length),
      },
      {
        label: "Select Incidents",
        optional: false,
        description: `Search for incidents or specify incident IDs.
        Incident alert events will be retrieved from BigPanda for the selected incidents and populated into the table below.
        You may export the raw data to a CSV file or continue to the next step to create and publish the events as a DemoSim scenario.
        `,
        component: (
          <>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={incidentSelectorChoice}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;

                  // Check if the value is one of the allowed types
                  if (value === "search" || value === "specify") {
                    setIncidentSelectorChoice(value);
                  }
                }}
              >
                <FormControlLabel
                  value="search"
                  control={<Radio />}
                  label="Search for Incidents"
                  // disabled={!isValidArrayOfObjects(dataObjects)}
                />
                <FormControlLabel
                  value="specify"
                  control={<Radio />}
                  label="Specify Incident IDs"
                />
              </RadioGroup>
            </FormControl>
            {incidentSelectorChoice === "specify" && (
              <FreeSoloChips
                data={selectedIncidents.map((incident: any) => incident.id)}
                setData={async (data) => {
                  setBpIncidentEvents([]);
                  setSelectedIncidents(data.map((d: any) => ({ id: d })));
                }}
                label="Incident IDs"
              />
            )}
            {incidentSelectorChoice === "search" && (
              <IncidentSearch
                selectedIncidents={selectedIncidents}
                setSelectedIncidents={(data) => {
                  setBpIncidentEvents([]);
                  setSelectedIncidents(data);
                }}
                expanded={true}
              />
            )}
          </>
        ),
        gate: selectedIncidents.length > 0,
      },
      {
        label: "Publish your scenario",
        optional: false,
        description: `Enter the name and details of your scenario, 
      then click the Publish button to add your scenario to this Demo Config.
      The original event data will be formatted as a DemoSim scenario and added to the Demo Config.
      From there, you can further edit and run your scenario in the Demo Admin Scenario tab.`,
        component: (
          <Stack
            direction="row"
            spacing={2}
            sx={{ m: 2, p: 1 }}
            // center the ScenarioCreator component
            justifyContent={"center"}
          >
            <ScenarioCreator newEvents={eventData} />
          </Stack>
        ),
        gate: true,
      },
    ];
  }, [
    eventsTable,
    demoConfig.bporgname,
    eventData,
    selectedIncidents,
    integrationTypes.length,
    incidentSelectorChoice,
    getIncidentEvents,
    setSelectedIncidents,
  ]);

  return (
    <Paper className="RouteContainer">
      <Typography sx={{ mb: 1 }} variant="h3">
        Scenario Lab
      </Typography>
      <HorizontalLinearStepper
        steps={steps}
        resetFunction={() => {
          setSelectedIncidents([]);
          setBpIncidentEvents([]);
          setEventData([]);
        }}
      />
      <MaterialReactTable table={eventsTable} />
      {/* {bpIncidentEvents.length > 0 && (
        <MaterialReactTable table={eventsTable} />
      )} */}
    </Paper>
  );
}
