import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";

// MUI components
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select"; // Import SelectChangeEvent
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

type Props = {
  fileNames: string[];
};

Storage.configure({ level: "public" });

export default function CliDownloads({ fileNames }: Props) {
  const [files, setFiles] = useState(() =>
    fileNames.map((filename) => ({ filename, href: null }))
  );
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  // Find the longest filename to calculate dynamic width
  const longestFileNameLength = Math.max(
    ...fileNames.map((name) => name.length)
  );
  const calculatedWidth = `${longestFileNameLength + 2}ch`; // Adding some buffer for padding

  useEffect(() => {
    const getLinks = fileNames.map((filename) =>
      Storage.get(`setools/${filename}`, { expires: 3600 })
    );
    Promise.all(getLinks).then((links) => {
      setFiles(
        links.map((link, index) => ({ filename: fileNames[index], href: link }))
      );
    });
  }, [fileNames, setFiles]);

  const handleFileSelect = (event: SelectChangeEvent<string>) => {
    // Updated event type
    const selectedFilename = event.target.value;
    setSelectedFile(selectedFilename);

    const file = files.find((file) => file.filename === selectedFilename);
    if (file && file.href) {
      window.location.href = file.href; // Trigger file download
    }
  };

  return (
    <Stack direction={"column"} spacing={2}>
      <Typography variant="h6">CLI EXECUTABLES</Typography>
      <Typography variant="body2" sx={{ width: calculatedWidth }}>
        Use CLI executable to perform the same function from command shell,
        scripts, etc. Use --help to see options.
      </Typography>
      <Divider variant="middle" />

      <FormControl fullWidth>
        <InputLabel>Select File to Download</InputLabel>
        <Select
          value={selectedFile || ""}
          onChange={handleFileSelect} // No change here, only updated the function type
          label="Select File to Download"
          sx={{ width: calculatedWidth }}
        >
          {files.map((file) => (
            <MenuItem key={file.filename} value={file.filename}>
              {file.filename}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
