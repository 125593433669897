/* 
  ScenarioEditorV2.tsx
  This component is a new version of the ScenarioEditor component that uses the Material-UI DataGrid component to display and edit scenario events and changes.
  This component is a work in progress and is not yet complete.
  It is intended to replace the original ScenarioEditor component.

  the only property passed in is the scenario ID
  The entire scenario is treated as an idempotent dataset. The editor facilitates local editing of the scenario data, and then posts the entire scenario back to the library.
  Events and Changes are stored as JSON strings in the database but here they are parsed into objects for editing and then stringified before posting back to the library.
  Event and Change tags are flattened localy into columns for editing, and then reconstituted into a tags object before posting back to the library.

  Mark 2: Use native event type

*/

import { useMemo, useState, useContext, useCallback, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
  createRow,
} from "material-react-table";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";

// mui components
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

// Templates
import {
  userGroups,
  menuCategories,
} from "../../templates/initialStateTemplates";

import {
  getDefaultMRTOptions,
  createHandleCreatingRowCancel,
  createHandleCreatingRowSave,
  createHandleEditingRowSave,
  createHandleDeleteSelected,
  createHandleCloneSelected,
  createHandleCreatingRowChange,
  createHandleMuiEditTextFieldOnChange,
  createHandleMuiEditTextFieldOnBlur,
} from "../Tables/MRT_Factories";

// utilities
import { v4 as uuidv4 } from "uuid";
import { Logger } from "aws-amplify";
import { useSnackbar } from "notistack";
import useServerStateMutations, {
  useDemoConfig,
  useScenario,
  useUpdateScenario,
} from "../../store/serverState";
import AuthContext from "../../store/AuthContext";
import useAppState from "../../store/appState";
import {
  useEventValidation,
  useChangeValidation,
} from "../../validators/scenarioValidators";
import {
  getNestedValue,
  extractUniqueKeysFromProperty,
  extractUniqueKeysFromData,
  gatherPropertyPaths,
} from "../../lib/transform_funcs";

// custom components
import ScenarioTestModal from "../ScenarioTest/ScenarioTest";
import FreeSoloChips from "../../components/Inputs/FreeSoloChips";
import DeleteScenarioDialog from "../DeleteScenarioDialog/DeleteScenarioDialogV2";
import CloneScenarioDialog from "../CloneScenarioDialog/CloneScenarioDialogV2";
import { removeUndefined, enforceSchema } from "../../lib/transform_funcs";
import MrtToolbarButtonSearchReplace from "../Tables/MRT_ToolbarButton_SearchReplace";
import TableColumnMenuDelete from "../Tables/MRT_ColumnMenuDelete";
import TableColumnMenuInsert from "../Tables/MRT_ColumnMenuInsert";
import TableColumnMenuClone from "../Tables/MRT_ColumnMenuClone";
import MrtToolbarButtonExport from "../Tables/MRT_ToolbarButton_Export";
import MrtToolbarButtonCreate from "../Tables/MRT_ToolbarButton_Create";
import MrtToolbarButtonDelete from "../Tables/MRT_ToolbarButton_Delete";
import MrtToolbarButtonClone from "../Tables/MRT_ToolbarButton_Clone";

// Types
import type { Scenario, Event, Change } from "../../types/API";

// Define a type that extends Event and adds an id property
type EventWithId = Event & {
  id: string;
  tags: Record<string, any>;
};
// Define a type definition, where known properties have enforced types
//@ts-ignore
const EventWithIdTypeDefinition: EventWithId = {
  id: "string",
  _offset: 0,
  seconds: 0,
};

type ChangeWithId = Change & {
  id: string;
  tags: Record<string, any>;
};

// Define a type definition, where known properties have enforced types
//@ts-ignore
const ChangeWithIdTypeDefinition: ChangeWithId = {
  id: "string",
  _offset: 0,
};

type ValidationState = {
  [uuid: string]: {
    [property: string]: string; // Each uuid has string properties
  };
};

type Props = {
  scenarioID: Scenario["id"];
};

// Constants
const logger = new Logger("ScenarioEditor", "INFO");
const eventColumnHelper = createMRTColumnHelper<EventWithId>();
const defaultEventMRTOptions = getDefaultMRTOptions<EventWithId>();
const changeColumnHelper = createMRTColumnHelper<ChangeWithId>();
const defaultChangeMRTOptions = getDefaultMRTOptions<ChangeWithId>();
const sortByOffset = (a, b) => a._offset - b._offset;

// Transform functions
const eventsToMrtData = (events: Event[]): EventWithId[] => {
  //@ts-ignore
  return events.map((event: Event) => {
    const { tags, ...rest } = event;

    return enforceSchema<EventWithId>(EventWithIdTypeDefinition, {
      ...rest,
      id: uuidv4(),
      tags: JSON.parse(tags),
    });
  });
};

const mrtDataToEvents = (rows: EventWithId[]): Event[] => {
  return rows.map((row) => {
    const { id, tags, ...rest } = row;
    return {
      ...rest,
      tags: JSON.stringify(tags),
    };
  });
};

const changesToMrtData = (changes: Change[]): ChangeWithId[] => {
  //@ts-ignore
  return changes.map((change) => {
    const { tags, ...rest } = change;
    return enforceSchema<ChangeWithId>(ChangeWithIdTypeDefinition, {
      ...rest,
      id: uuidv4(),
      tags: JSON.parse(tags),
    });
  });
};

const mrtDataToChanges = (rows: ChangeWithId[]): Change[] => {
  return rows.map((row) => {
    const { id, tags, ...rest } = row;
    return {
      ...rest,
      tags: JSON.stringify(tags),
    };
  });
};

export default function ScenarioEditor({ scenarioID }: Props) {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { user } = useContext(AuthContext);
  const integrations = useMemo(
    () => demoConfig.integrations,
    [demoConfig.integrations]
  );
  const integrationTypes = useMemo(
    () => integrations.map((i) => i.integration_type),
    [integrations]
  );

  const validateEvent = useEventValidation(
    integrationTypes.filter((t) => t !== "change-man")
  );
  const validateChange = useChangeValidation(
    integrationTypes.filter((t) => t === "change-man")
  );

  // Local state
  const {
    data: scenarioData,
    isLoading: isScenerioLoading,
    error: scenarioError,
    isError: isLoadingScenarioError,
    isFetching: isFetchingScenario,
  } = useScenario(scenarioID);

  const [name, setName] = useState(scenarioData?.name);
  const [category, setCategory] = useState(scenarioData?.category);
  const [description, setDescription] = useState(scenarioData?.description);
  const [owners, setOwners] = useState(scenarioData?.owners);
  const [groupsCanRead, setGroupsCanRead] = useState(
    scenarioData?.groupsCanRead
  );
  const [groupsCanEdit, setGroupsCanEdit] = useState(
    scenarioData?.groupsCanEdit
  );
  const [removingFromOrg, setRemovingFromOrg] = useState(false);
  const [deletingScenario, setDeletingScenario] = useState(false);
  const [scenarioChanged, setScenarioChanged] = useState(false);

  const [eventRowsSelection, setEventRowsSelection] =
    useState<MRT_RowSelectionState>({});
  const [changeRowsSelection, setChangeRowsSelection] =
    useState<MRT_RowSelectionState>({});

  const [eventValidationErrors, setEventValidationErrors] =
    useState<ValidationState>({} as ValidationState);
  const [changeValidationErrors, setChangeValidationErrors] =
    useState<ValidationState>({} as ValidationState);

  const [eventData, setEventData] = useState<EventWithId[]>(() => {
    const processedEventRows = eventsToMrtData(scenarioData?.events) || [];
    setEventValidationErrors(
      removeUndefined(
        processedEventRows.reduce((acc, row) => {
          return {
            ...acc,
            ...validateEvent(row),
          };
        }, {})
      )
    );
    return processedEventRows;
  });
  const [changeData, setChangeData] = useState<ChangeWithId[]>(() => {
    const processedChangesRows = changesToMrtData(scenarioData?.changes) || [];
    setChangeValidationErrors(
      removeUndefined(
        processedChangesRows.reduce((acc, row) => {
          return {
            ...acc,
            ...validateChange(row),
          };
        }, {})
      )
    );
    return processedChangesRows;
  });
  // Initialize local state
  const defineEventColumns = useCallback(
    (data: EventWithId[]): MRT_ColumnDef<EventWithId>[] => {
      // reducer to extract all keys from tags object in eventData
      const allPropertyPaths = new Set<string>();
      // Gather all unique property paths from all objects in the data array
      data.forEach((item) => {
        gatherPropertyPaths(item, "", allPropertyPaths);
      });

      // tagKeys needed to populate the select options for the primary_property and secondary_property columns
      const tagKeys = Array.from(allPropertyPaths).filter(
        (tag) =>
          ![
            "id",
            "event_type",
            "_offset",
            "seconds",
            "integration_type",
            "primary_property",
            "secondary_property",
            "tags.status",
            "tags",
          ].includes(tag)
      );

      const metaColumnDefs = [
        eventColumnHelper.accessor((row) => row["id"], {
          header: "ID",
          id: "id",
          size: 200,
          visibleInShowHideMenu: false,
        }),
        eventColumnHelper.accessor((row) => row["event_type"], {
          header: "Event Type",
          id: "event_type",
          size: 160,
          editVariant: "select",
          editSelectOptions: ["ALERT", "ITAG", "PAUSE"],
        }),
        eventColumnHelper.accessor((row) => Number(row["_offset"]), {
          header: "Offset",
          id: "_offset",
          size: 130,
          editVariant: "text",
          muiEditTextFieldProps: {
            type: "number",
          },
        }),
        eventColumnHelper.accessor((row) => Number(row["seconds"]), {
          header: "Seconds",
          id: "seconds",
          size: 150,
          editVariant: "text",
          muiEditTextFieldProps: {
            type: "number",
          },
        }),
        eventColumnHelper.accessor((row) => row["integration_type"], {
          header: "Integration Type",
          id: "integration_type",
          size: 190,
          editVariant: "select",
          editSelectOptions: integrationTypes.filter((t) => t !== "change-man"),
        }),
        eventColumnHelper.accessor((row) => row["primary_property"], {
          header: "Primary Property",
          id: "primary_property",
          size: 200,
          editVariant: "select",
          // provide list of event tags to select from (excluding id, event_type, _offset, seconds, integration_type, primary_property, secondary_property, and status)
          editSelectOptions: tagKeys.map(
            (path) => path.split(".").pop() || path
          ),
        }),
        eventColumnHelper.accessor((row) => row["secondary_property"], {
          header: "Secondary Property",
          id: "secondary_property",
          size: 210,
          editVariant: "select",
          // provide list of event tags to select from (excluding id, event_type, _offset, seconds, integration_type, primary_property, secondary_property, and status)
          editSelectOptions: tagKeys.map(
            (path) => path.split(".").pop() || path
          ),
        }),
        eventColumnHelper.accessor((row) => row.tags && row.tags["status"], {
          header: "Status",
          id: "tags.status",
          size: 130,
        }),
      ];
      const tagColumnDefs = tagKeys.map((path) => ({
        accessorFn: (row) =>
          path.split(".").reduce((acc, key) => acc?.[key], row),
        id: path,
        header: path.split(".").pop() || path, // Use the last part of the path as the header
        renderColumnActionsMenuItems: ({
          closeMenu,
          internalColumnMenuItems,
          column,
        }) => [
          ...internalColumnMenuItems,
          <Divider key="column-divider" />,
          <TableColumnMenuDelete
            key="delete-column"
            setData={setEventData}
            updateColumns={(data) => setEventColumns(defineEventColumns(data))}
            setIsDataChanged={setScenarioChanged}
            setValidationErrors={setEventValidationErrors}
            validateFn={validateEvent}
            column={column}
            columnDescription="tag"
            closeMenu={closeMenu}
          />,
          <TableColumnMenuInsert
            key="insert-column"
            data={eventData}
            setData={setEventData}
            updateColumns={(data) =>
              // @ts-ignore
              setEventColumns(defineEventColumns(data))
            }
            setIsDataChanged={setScenarioChanged}
            setValidationErrors={setEventValidationErrors}
            //@ts-ignore
            column={column}
            columnDescription="tag"
            closeMenu={closeMenu}
            validateFn={validateEvent}
          />,
          <TableColumnMenuClone
            key="clone-column"
            data={eventData}
            setData={setEventData}
            updateColumns={(data) =>
              // @ts-ignore
              setEventColumns(defineEventColumns(data))
            }
            setIsDataChanged={setScenarioChanged}
            setValidationErrors={setEventValidationErrors}
            //@ts-ignore
            column={column}
            columnDescription="tag"
            closeMenu={closeMenu}
            validateFn={validateEvent}
          />,
        ],
      }));
      logger.info("new event column defs:", [
        ...metaColumnDefs,
        ...tagColumnDefs,
      ]);
      return [...metaColumnDefs, ...tagColumnDefs];
    },
    [eventData, integrationTypes, validateEvent]
  );

  const defineChangeColumns = useCallback(
    (data: ChangeWithId[]): MRT_ColumnDef<ChangeWithId>[] => {
      const allPropertyPaths = new Set<string>();
      // Gather all unique property paths from all objects in the data array
      data.forEach((item) => {
        gatherPropertyPaths(item, "", allPropertyPaths);
      });
      const tagKeys = Array.from(allPropertyPaths).filter(
        (tag) =>
          ![
            "id",
            "_offset",
            "seconds",
            "integration_type",
            "identifier",
            "status",
            "summary",
            "ticket_url",
            "tags",
          ].includes(tag)
      );

      const metaColumnDefs = [
        changeColumnHelper.accessor((row) => row["id"], {
          header: "ID",
          id: "id",
          size: 200,
          visibleInShowHideMenu: false,
        }),
        changeColumnHelper.accessor((row) => Number(row["_offset"]), {
          header: "Offset",
          id: "_offset",
          size: 130,
          editVariant: "text",
          muiEditTextFieldProps: {
            type: "number",
          },
        }),
        changeColumnHelper.accessor((row) => row["integration_type"], {
          header: "Integration Type",
          id: "integration_type",
          size: 190,
          editVariant: "select",
          editSelectOptions: ["change-man"],
        }),
        changeColumnHelper.accessor((row) => row["identifier"], {
          header: "Identifier",
          id: "identifier",
          size: 190,
        }),
        changeColumnHelper.accessor((row) => row["status"], {
          header: "Status",
          id: "status",
          size: 210,
          editVariant: "select",
          editSelectOptions: ["Planned", "In Progress", "Done", "Canceled"],
        }),
        changeColumnHelper.accessor((row) => row["summary"], {
          header: "Summary",
          id: "summary",
          size: 210,
        }),
        changeColumnHelper.accessor((row) => row["ticket_url"], {
          header: "Ticket URL",
          id: "ticket_url",
          size: 210,
          Cell: ({ renderedCellValue }) => (
            //@ts-ignore
            <Link
              href={`${renderedCellValue}`}
              underline="always"
              target="_blank"
              rel="noreferrer"
            >
              {renderedCellValue}
            </Link>
          ),
        }),
      ];
      const tagColumnDefs = tagKeys.map((path) => ({
        accessorFn: (row) =>
          path.split(".").reduce((acc, key) => acc?.[key], row),
        id: path,
        header: path.split(".").pop() || path, // Use the last part of the path as the header
        renderColumnActionsMenuItems: ({
          closeMenu,
          internalColumnMenuItems,
          column,
        }) => [
          ...internalColumnMenuItems,
          <Divider key="column-divider" />,
          <TableColumnMenuDelete
            key="delete-column"
            setData={setChangeData}
            updateColumns={(data) =>
              setChangeColumns(defineChangeColumns(data))
            }
            setIsDataChanged={setScenarioChanged}
            setValidationErrors={setChangeValidationErrors}
            validateFn={validateChange}
            column={column}
            columnDescription="tag"
            closeMenu={closeMenu}
          />,
          <TableColumnMenuInsert
            key="insert-column"
            data={changeData}
            setData={setChangeData}
            updateColumns={(data) =>
              setChangeColumns(defineChangeColumns(data))
            }
            setIsDataChanged={setScenarioChanged}
            setValidationErrors={setChangeValidationErrors}
            column={column}
            columnDescription="tag"
            closeMenu={closeMenu}
            validateFn={validateChange}
          />,
          <TableColumnMenuClone
            key="clone-column"
            data={changeData}
            setData={setChangeData}
            updateColumns={(data) =>
              setChangeColumns(defineChangeColumns(data))
            }
            setIsDataChanged={setScenarioChanged}
            setValidationErrors={setChangeValidationErrors}
            column={column}
            columnDescription="tag"
            closeMenu={closeMenu}
            validateFn={validateChange}
          />,
        ],
      }));
      logger.info("new change column defs:", [
        ...metaColumnDefs,
        ...tagColumnDefs,
      ]);
      return [...metaColumnDefs, ...tagColumnDefs];
    },
    [changeData, validateChange]
  );
  const [eventColumns, setEventColumns] = useState<
    MRT_ColumnDef<EventWithId>[]
  >(() => defineEventColumns(eventData));

  const [changeColumns, setChangeColumns] = useState<
    MRT_ColumnDef<ChangeWithId>[]
  >(() => defineChangeColumns(changeData));

  // Functions
  const handleCreatingEventChange = createHandleCreatingRowChange<EventWithId>({
    setValidationErrors: setEventValidationErrors,
    validateFn: validateEvent,
  });
  const handleCreatingChangesChange =
    createHandleCreatingRowChange<ChangeWithId>({
      setValidationErrors: setChangeValidationErrors,
      validateFn: validateChange,
    });

  const handleCancelCreateEvent = createHandleCreatingRowCancel<EventWithId>({
    setValidationErrors: setEventValidationErrors,
  });
  const handleCancelCreateChangesRow =
    createHandleCreatingRowCancel<ChangeWithId>({
      setValidationErrors: setChangeValidationErrors,
    });
  const handleCreatingEventSave = createHandleCreatingRowSave<EventWithId>({
    setIsDataChanged: setScenarioChanged,
    setValidationErrors: setEventValidationErrors,
    validateFn: validateEvent,
    setData: setEventData,
    sortFn: sortByOffset,
  });
  const handleCreatingChangesRowSave =
    createHandleCreatingRowSave<ChangeWithId>({
      setIsDataChanged: setScenarioChanged,
      setValidationErrors: setChangeValidationErrors,
      validateFn: validateChange,
      setData: setChangeData,
      sortFn: sortByOffset,
    });
  const handleEditingEventSave = createHandleEditingRowSave<EventWithId>({
    setIsDataChanged: setScenarioChanged,
    setValidationErrors: setEventValidationErrors,
    validateFn: validateEvent,
    setData: setEventData,
    sortFn: sortByOffset,
  });
  const handleEditingChangesRowSave = createHandleEditingRowSave<ChangeWithId>({
    setIsDataChanged: setScenarioChanged,
    setValidationErrors: setChangeValidationErrors,
    validateFn: validateChange,
    setData: setChangeData,
    sortFn: sortByOffset,
  });
  const handleDeleteSelectedEvents = createHandleDeleteSelected<EventWithId>({
    setIsDataChanged: setScenarioChanged,
    setValidationErrors: setEventValidationErrors,
    setSelection: setEventRowsSelection,
    setData: setEventData,
    selection: eventRowsSelection,
  });
  const handleDeleteSelectedChanges = createHandleDeleteSelected<ChangeWithId>({
    setIsDataChanged: setScenarioChanged,
    setValidationErrors: setChangeValidationErrors,
    setSelection: setChangeRowsSelection,
    setData: setChangeData,
    selection: changeRowsSelection,
  });
  const handleCloneSelectedEvents = createHandleCloneSelected<EventWithId>({
    setIsDataChanged: setScenarioChanged,
    setSelection: setEventRowsSelection,
    setValidationErrors: setEventValidationErrors,
    validateFn: validateEvent,
    setData: setEventData,
    sortFn: sortByOffset,
    selection: eventRowsSelection,
    data: eventData,
  });
  const handleCloneSelectedChanges = createHandleCloneSelected<ChangeWithId>({
    setIsDataChanged: setScenarioChanged,
    setSelection: setChangeRowsSelection,
    setValidationErrors: setChangeValidationErrors,
    validateFn: validateChange,
    setData: setChangeData,
    sortFn: sortByOffset,
    selection: changeRowsSelection,
    data: changeData,
  });
  const handleEventEditTextFieldOnChange = createHandleMuiEditTextFieldOnChange(
    {
      setIsDataChanged: setScenarioChanged,
      setValidationErrors: setEventValidationErrors,
      validateFn: validateEvent,
    }
  );
  const handleChangesEditTextFieldOnChange =
    createHandleMuiEditTextFieldOnChange({
      setIsDataChanged: setScenarioChanged,
      setValidationErrors: setChangeValidationErrors,
      validateFn: validateChange,
    });
  const handleEventEditTextFieldOnBlur = createHandleMuiEditTextFieldOnBlur({
    setIsDataChanged: setScenarioChanged,
    setValidationErrors: setEventValidationErrors,
    validateFn: validateEvent,
    setData: setEventData,
  });
  const handleChangesEditTextFieldOnBlur = createHandleMuiEditTextFieldOnBlur({
    setIsDataChanged: setScenarioChanged,
    setValidationErrors: setChangeValidationErrors,
    validateFn: validateChange,
    setData: setChangeData,
  });

  const { mutateAsync: updateScenario, isPending: isUpdatingScenario } =
    useUpdateScenario();

  const { enqueueSnackbar } = useSnackbar();
  const { removeScenariosFromOrg } = useServerStateMutations();

  // Post scenario updates to library
  const handlePostScenarioUpdates = useCallback(() => {
    const updatedScenarioData = {
      id: scenarioID,
      name: name,
      description: description,
      category: category,
      groupsCanRead: [...groupsCanRead],
      groupsCanEdit: [...groupsCanEdit],
      owners: [...owners],
      events: mrtDataToEvents(eventData),
      changes: mrtDataToChanges(changeData),
    };
    logger.info(
      `[updateScenario] posting updateScenario mutation with updatedScenarioData:`,
      updatedScenarioData
    );
    setScenarioChanged(false);
    //@ts-ignore
    updateScenario(updatedScenarioData);
    // .then(() => setScenarioChanged(false));
  }, [
    scenarioID,
    name,
    description,
    category,
    groupsCanRead,
    groupsCanEdit,
    owners,
    eventData,
    changeData,
    updateScenario,
  ]);

  async function handleRemoveFromOrgClick(e) {
    setRemovingFromOrg(true);
    //@ts-ignore
    removeScenariosFromOrg.mutateAsync([scenarioID]);
  }

  function handleChangeScenarioName(e) {
    setScenarioChanged(true);
    setName(e.target.value);
  }

  async function handleChangeScenarioCategory(e) {
    setScenarioChanged(true);
    setCategory(e.target.value);
  }

  async function handleChangeScenarioDescription(e) {
    setScenarioChanged(true);
    setDescription(e.target.value);
  }

  const handleChangeOwners = (newOwners) => {
    setOwners(newOwners);
    setScenarioChanged(true);
  };

  const handleChangeGroupsCanRead = useCallback(
    (newGroups) => {
      setGroupsCanRead(newGroups);
      setScenarioChanged(true);
    },
    [setGroupsCanRead, setScenarioChanged]
  );

  // cancel scenario updates
  const handleCancelScenarioUpdates = useCallback(() => {
    logger.info("Cancelling scenario updates");
    setScenarioChanged(false);
    setName(scenarioData.name || "");
    setCategory(scenarioData.category || "");
    setDescription(scenarioData.description || "");
    setOwners(scenarioData.owners || []);
    setGroupsCanRead(scenarioData.groupsCanRead || []);
    setGroupsCanEdit(scenarioData.groupsCanEdit || []);

    const processedEventRows = eventsToMrtData(scenarioData.events);
    setEventValidationErrors(
      removeUndefined(
        processedEventRows.reduce((acc, row) => {
          return {
            ...acc,
            ...validateEvent(row),
          };
        }, {})
      )
    );
    setEventColumns(defineEventColumns(processedEventRows));
    setEventData(processedEventRows);

    if (scenarioData.changes) {
      const processedChangeRows = changesToMrtData(scenarioData.changes);
      setChangeValidationErrors(
        removeUndefined(
          processedChangeRows.reduce((acc, row) => {
            return {
              ...acc,
              ...validateChange(row),
            };
          }, {})
        )
      );
      setChangeColumns(defineChangeColumns(processedChangeRows));
      setChangeData(processedChangeRows);
    }
  }, [
    scenarioData,
    defineChangeColumns,
    defineEventColumns,
    validateEvent,
    validateChange,
  ]);

  useEffect(() => {
    logger.info("eventValidationErrors", eventValidationErrors);
  }, [eventValidationErrors]);

  const eventsTable = useMaterialReactTable({
    ...defaultEventMRTOptions,
    columns: eventColumns,
    data: eventData,
    getRowId: (row) => `${row.id}`,
    onCreatingRowCancel: handleCancelCreateEvent,
    onCreatingRowSave: handleCreatingEventSave,
    onCreatingRowChange: handleCreatingEventChange,
    onEditingRowSave: handleEditingEventSave,
    onRowSelectionChange: setEventRowsSelection,
    initialState: {
      ...defaultEventMRTOptions.initialState,
      sorting: [{ id: "_offset", desc: false }],
      columnVisibility: { id: false },
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: isScenerioLoading,
      isSaving: isUpdatingScenario,
      showAlertBanner: isLoadingScenarioError,
      showProgressBars: isFetchingScenario,
      rowSelection: eventRowsSelection,
    },
    muiTableBodyCellProps: ({ row, cell }) => ({
      sx: {
        borderStyle: getNestedValue(
          eventValidationErrors[row.original.id],
          cell.column.id
        )
          ? "solid"
          : undefined,
        borderColor: getNestedValue(
          eventValidationErrors[row.original.id],
          cell.column.id
        )
          ? "red"
          : undefined,
      },
    }),
    muiEditTextFieldProps: ({ cell, row, table, column }) => ({
      select: column.columnDef.editVariant === "select",
      error: !!getNestedValue(
        eventValidationErrors[row.original.id],
        cell.column.id
      ),
      helpertext:
        getNestedValue(
          eventValidationErrors[row.original.id],
          cell.column.id
        ) || "",
      onChange: (event) => handleEventEditTextFieldOnBlur(event, row, cell),
      onKeyDown: (event) => {
        // Don't change this until bug is fixed
        if (
          column.columnDef.editVariant === "select" &&
          event.key === "Enter" &&
          !event.shiftKey
        ) {
          event.shiftKey = true;
          table.setEditingCell(null);
        }
      },
      onBlur: (event) => handleEventEditTextFieldOnBlur(event, row, cell),
    }),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        // header: "Change Account Settings", //change header text
        size: 100, //make actions column wider
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={2}>
        <MrtToolbarButtonCreate
          table={table}
          dataRowTemplate={{
            id: uuidv4(),
            //@ts-ignore
            event_type: "",
            _offset: 0,
            seconds: 0,
            integration_type: "",
            primary_property: "",
            secondary_property: "",
            //@ts-ignore
            tags: extractUniqueKeysFromProperty(eventData, "tags").reduce(
              (acc, key) => {
                return {
                  ...acc,
                  [key]: "",
                };
              },
              {}
            ),
          }}
          recordDescription="Event"
          dataRowsSelection={eventRowsSelection}
        />
        <MrtToolbarButtonDelete
          recordDescription="Event"
          dataRowsSelection={eventRowsSelection}
          onDelete={handleDeleteSelectedEvents}
        />
        <MrtToolbarButtonClone
          recordDescription="Event"
          dataRowsSelection={eventRowsSelection}
          onClone={handleCloneSelectedEvents}
        />
        <MrtToolbarButtonSearchReplace
          data={eventData}
          setData={setEventData}
          validateFn={validateEvent}
          setValidationErrors={setEventValidationErrors}
          setScenarioChanged={setScenarioChanged}
        />
        <MrtToolbarButtonExport table={table} />
      </Stack>
    ),
  });

  const changesTable = useMaterialReactTable({
    ...defaultChangeMRTOptions,
    columns: changeColumns,
    data: changeData,
    getRowId: (row) => `${row.id}`,
    onCreatingRowChange: handleCreatingChangesChange,
    onCreatingRowCancel: handleCancelCreateChangesRow,
    onCreatingRowSave: handleCreatingChangesRowSave,
    onEditingRowSave: handleEditingChangesRowSave,
    onRowSelectionChange: setChangeRowsSelection,

    initialState: {
      ...defaultChangeMRTOptions.initialState,
      sorting: [{ id: "_offset", desc: false }],
      columnVisibility: { id: false },
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: isScenerioLoading,
      isSaving: isUpdatingScenario,
      showAlertBanner: isLoadingScenarioError,
      showProgressBars: isFetchingScenario,
      rowSelection: changeRowsSelection,
    },

    muiTableBodyCellProps: ({ row, cell }) => ({
      sx: {
        borderStyle: getNestedValue(
          changeValidationErrors[row.original.id],
          cell.column.id
        )
          ? "solid"
          : undefined,
        borderColor: getNestedValue(
          changeValidationErrors[row.original.id],
          cell.column.id
        )
          ? "red"
          : undefined,
      },
    }),
    muiEditTextFieldProps: ({ cell, row, table, column }) => ({
      select: column.columnDef.editVariant === "select",
      error: !!getNestedValue(
        changeValidationErrors[row.original.id],
        cell.column.id
      ),
      helpertext:
        getNestedValue(
          changeValidationErrors[row.original.id],
          cell.column.id
        ) || "",
      onChange: (event) => handleChangesEditTextFieldOnChange(event, row, cell),
      onKeyDown: (event) => {
        // Don't change this until bug is fixed
        if (
          column.columnDef.editVariant === "select" &&
          event.key === "Enter" &&
          !event.shiftKey
        ) {
          event.shiftKey = true;
          table.setEditingCell(null);
        }
      },
      onBlur: (event) => handleChangesEditTextFieldOnBlur(event, row, cell),
    }),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        // header: "Change Account Settings", //change header text
        size: 100, //make actions column wider
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Button
          variant="contained"
          disabled={Object.keys(changeRowsSelection).length > 0}
          onClick={() => {
            table.setCreatingRow(
              //@ts-ignore
              createRow(table, {
                ...extractUniqueKeysFromData(changeData).reduce((acc, key) => {
                  return {
                    ...acc,
                    [key]: "",
                  };
                }, {}),
                id: uuidv4(),
                _offset: 0,
              })
            );
          }}
        >
          Create Row
        </Button>
        <Button
          variant="contained"
          onClick={handleDeleteSelectedChanges}
          disabled={Object.keys(changeRowsSelection).length === 0}
        >
          DELETE ROW(S)
        </Button>
        <Button
          variant="contained"
          onClick={handleCloneSelectedChanges}
          disabled={Object.keys(changeRowsSelection).length === 0}
        >
          CLONE ROW(S)
        </Button>
        <MrtToolbarButtonSearchReplace
          data={changeData}
          setData={setChangeData}
          validateFn={validateChange}
          setValidationErrors={setChangeValidationErrors}
          setScenarioChanged={setScenarioChanged}
        />
        <MrtToolbarButtonExport table={table} />
      </>
    ),
  });

  return removingFromOrg ? (
    <>
      <Alert severity="info">Removing scenario from demo config...</Alert>
      <LinearProgress />
    </>
  ) : deletingScenario ? (
    <>
      <Alert severity="info">Deleting scenario...</Alert>
      <LinearProgress />
    </>
  ) : scenarioError ? (
    <>
      <Alert severity="error">Error loading scenario...</Alert>
      <LinearProgress />
    </>
  ) : (
    <Grid
      container
      spacing={2}
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ScenarioTestModal
          eventData={eventData}
          changeData={changeData}
          scenarioName={name}
        />
      </Grid>
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          color="warning"
          variant="contained"
          size="medium"
          onClick={handleRemoveFromOrgClick}
        >
          REMOVE FROM CONFIG
        </Button>
      </Grid>
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CloneScenarioDialog scenarioID={scenarioID} />
      </Grid>
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <DeleteScenarioDialog
          scenarioID={scenarioID}
          setDeletingScenario={setDeletingScenario}
        />
      </Grid>
      <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
        <ButtonGroup
          sx={{ my: 2 }}
          variant="contained"
          // orientation="vertical"
          aria-label="outlined primary button group"
          disabled={!!!scenarioChanged}
        >
          <Button
            id="btn-org-change-submit"
            size="small"
            sx={{ height: "max-content" }}
            color="success"
            onClick={handlePostScenarioUpdates}
            disabled={owners && !owners.includes(user.username)}
          >
            POST SCENARIO UPDATES TO STORE
          </Button>
          <Button
            id="btn-org-change-abandon"
            size="small"
            sx={{ height: "max-content" }}
            color="error"
            onClick={handleCancelScenarioUpdates}
            disabled={owners && !owners.includes(user.username)}
          >
            Abandon updates
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid
        xs={12}
        md={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="scenario-name"
          label="Scenario Name"
          fullWidth
          sx={{ minHeight: "max-content" }}
          disabled={owners && !owners.includes(user.username)}
          // helperText="Displays on the Chrome Extension"
          // variant="standard"
          value={name}
          onChange={handleChangeScenarioName}
        />
      </Grid>
      <Grid
        xs={12}
        md={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="select-scenario-category"
          select
          label="Category"
          sx={{ minHeight: "max-content" }}
          fullWidth
          disabled={owners && !owners.includes(user.username)}
          value={category}
          onChange={handleChangeScenarioCategory}
          // helperText="Category for the Chrome Extension"
        >
          {menuCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="scenario-description"
          label="Scenario Description"
          fullWidth
          sx={{ minHeight: "max-content" }}
          multiline
          disabled={owners && !owners.includes(user.username)}
          // helperText="Summarize the scenario script"
          // variant="standard"
          value={description}
          onChange={handleChangeScenarioDescription}
        />
      </Grid>
      <Grid
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <FreeSoloChips
          label="Groups Can Use"
          data={groupsCanRead}
          setData={handleChangeGroupsCanRead}
          options={userGroups}
          disabled={owners && !owners.includes(user.username)}
        />
      </Grid>
      <Grid
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <FreeSoloChips
          label="Scenario Owners"
          data={owners}
          setData={handleChangeOwners}
          disabled={owners && !owners.includes(user.username)}
          validate={(newValue, newDataset) => {
            if (!newDataset.includes(user.username)) {
              enqueueSnackbar(
                `Sorry, you can't remove yourself (${user.username}). Add someone else to the owners list, then they can remove you.`,
                { variant: "error" }
              );
              return false;
            }
            if (newValue === user.username) {
              enqueueSnackbar(
                `Sorry, you can't add yourself (${user.username}). Ask someone else in the owners list to add you.`,
                { variant: "error" }
              );
              return false;
            }
            return true;
          }}
        />
      </Grid>

      <Grid xs={12} display="flex">
        <Card sx={{ boxShadow: 2, flex: 1 }}>
          <CardHeader
            avatar={<Assignment color="primary" />}
            title="Events"
            titleTypographyProps={{ color: "primary", variant: "h4" }}
          />
          <CardContent>
            {/* if eventValidationErrors length is > 0, display an alert for each error showing the row number, property, and error */}
            {Object.keys(eventValidationErrors).length > 0 &&
              Object.keys(eventValidationErrors).map((rowId) => {
                return Object.keys(eventValidationErrors[rowId]).map(
                  (property) => {
                    let rowNumber =
                      eventData.findIndex((row) => row.id === rowId) + 1;
                    return (
                      <Alert
                        severity="error"
                        variant="outlined"
                        sx={{ mb: 1 }}
                        key={rowId + property}
                      >
                        {rowNumber === 0 ? `New Row` : `Row ${rowNumber}`}
                        {` - ${property}:  ${eventValidationErrors[rowId][property]}`}
                      </Alert>
                    );
                  }
                );
              })}

            <MaterialReactTable table={eventsTable} />
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} display="flex">
        <Card sx={{ boxShadow: 2, flex: 1 }}>
          <CardHeader
            avatar={<Assignment color="primary" />}
            title="Changes"
            titleTypographyProps={{ color: "primary", variant: "h4" }}
          />
          <CardContent>
            {/* if changeValidationErrors length is > 0, display an alert for each error showing the row number, property, and error */}
            {Object.keys(changeValidationErrors).length > 0 &&
              Object.keys(changeValidationErrors).map((rowId) => {
                return Object.keys(changeValidationErrors[rowId]).map(
                  (property) => {
                    let rowNumber =
                      changeData.findIndex((row) => row.id === rowId) + 1;
                    return (
                      <Alert
                        severity="error"
                        variant="outlined"
                        sx={{ mb: 1 }}
                        key={rowId + property}
                      >
                        {rowNumber === 0 ? `New Row` : `Row ${rowNumber}`}
                        {` - ${property}:  ${changeValidationErrors[rowId][property]}`}
                      </Alert>
                    );
                  }
                );
              })}
            <MaterialReactTable table={changesTable} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
