import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import { type MRT_Row, type MRT_TableInstance } from "material-react-table";
import { flattenObject } from "../../lib/transform_funcs";
var R = require("ramda");

interface MrtToolbarButtonExportProps<TData> {
  table: MRT_TableInstance<TData>;
}

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export default function MrtToolbarButtonExport<TData>({
  table,
}: MrtToolbarButtonExportProps<TData>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportRows = (rows: MRT_Row<TData>[]) => {
    const clonedData = R.clone(rows.map((row) => row.original));
    const rowData = clonedData
      .map((row) => {
        delete row.id;
        return row;
      })
      // merge nested tags
      .map((row) => {
        if (row.tags) {
          let { tags } = row;
          delete row.tags;
          return { ...row, ...tags };
        }
        return row;
      })
      // flatten nested objects
      .map(flattenObject());
    console.log("rowData:", rowData);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Export data to a CSV file">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          startIcon={<FileDownloadIcon />}
          onClick={handleClick}
          variant="contained"
          size="small"
        >
          Export
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Tooltip
          title="Export all rows, including from the all pages, (respects filtering and sorting)"
          placement="right"
        >
          <MenuItem
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
          >
            Export All Rows
          </MenuItem>
        </Tooltip>
        <Tooltip
          title="Export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)"
          placement="right"
        >
          <MenuItem
            disabled={table.getRowModel().rows.length === 0}
            onClick={() => handleExportRows(table.getRowModel().rows)}
          >
            Export Page Rows
          </MenuItem>
        </Tooltip>
        <Tooltip title="Export selected rows only" placement="right">
          <MenuItem
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          >
            Export Selected Rows
          </MenuItem>
        </Tooltip>
      </Menu>
    </div>
  );
}
