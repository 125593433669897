import React, { useState, useEffect, Suspense } from "react";
import { Logger } from "aws-amplify";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
// MUI components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
// custom components
import ScenarioImport from "../../components/ScenarioImport/ScenarioImport";
import ScenarioStore from "components/ScenarioStore/ScenarioStore";
import ScenarioEditor from "components/ScenarioEditor/ScenarioEditor";
import ScenarioCreator from "components/ScenarioCreator/ScenarioCreator";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import useAppState from "store/appState";
import { useDemoConfig, useOrgScenarios } from "store/serverState";

const logger = new Logger("OrgScenariosTab", "INFO");

const OrgScenariosTab = () => {
  const { currentDemoConfigId } = useAppState();
  const { bpOrgInfo } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const [openStoreDialog, setOpenStoreDialog] = useState(false);

  useEffect(() => {
    logger.info("Loaded OrgScenarios:", orgScenerios);
  }, [orgScenerios]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h5">{`DemoSim Scenarios for ${bpOrgInfo.organization.name}`}</Typography>
      </Grid>
      <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="caption">
          You can add, remove, and edit DemoSim scenarios here.
        </Typography>
      </Grid>
      <Grid
        container
        id="Scenario Tab Actions"
        xs={12}
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid xs={12} md={6} lg={4} display="flex" justifyContent="center">
          <Button
            id="btn-scenario-store-open"
            data-test="btn-scenario-store-open"
            sx={{ minWidth: "max-content" }}
            variant="outlined"
            color="info"
            size="small"
            onClick={() => setOpenStoreDialog(true)}
            startIcon={<LocalGroceryStoreIcon />}
          >
            Scenario Store
          </Button>

          <ScenarioStore
            openStoreDialog={openStoreDialog}
            setOpenStoreDialog={setOpenStoreDialog}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4} display="flex" justifyContent="center">
          <ScenarioImport />
        </Grid>
        <Grid xs={12} md={6} lg={4} display="flex" justifyContent="center">
          <ScenarioCreator />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider variant="middle" sx={{ mb: 2 }} />
      </Grid>
      <Grid xs={12} sx={{ mb: 6 }}>
        {orgScenerios.length ? (
          orgScenerios
            .sort((a, b) => a?.scenario?.name.localeCompare(b?.scenario?.name))
            .map((orgScenario) => (
              <Accordion
                id="scenario-accordian"
                key={`scenarioID-${orgScenario.scenarioID}`}
                slotProps={{ transition: { unmountOnExit: true } }}
                sx={{
                  mb: 2,
                }}
              >
                <AccordionSummary
                  id={`${orgScenario.scenarioID}-ScenarioEdit-header`}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${orgScenario.scenarioID}-ScenarioEdit-content`}
                  data-test={`scenario-accordian-${orgScenario.scenarioID}`}
                  sx={{ boxShadow: 1, borderRadius: 1 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ boxShadow: 1, p: 1, borderRadius: 3 }}
                  >
                    {orgScenario.scenario.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails id="ScenarioEdit Details" sx={{ mt: 2 }}>
                  <Suspense fallback="...loading Scenario">
                    <ScenarioEditor scenarioID={orgScenario.scenarioID} />
                  </Suspense>
                </AccordionDetails>
              </Accordion>
            ))
        ) : (
          <div>
            <LoadingSpinner />
            <Alert
              data-test="alert-empty-scenarios"
              severity="info"
              margin={4}
              padding={2}
            >
              You have no DemoSim scenarios linked to this Demo Config
            </Alert>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default OrgScenariosTab;
