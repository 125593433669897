import { ReactNode, SyntheticEvent, useState } from "react";
// import { Logger } from "aws-amplify";

// MUI components
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Custom components
import BpChangeLoader from "./ToolTabPanels/BpChangeLoader.tsx";
import BpClearIncidents from "./ToolTabPanels/BpClearIncidents.tsx";
import DataPoster from "./ToolTabPanels/DataPoster.tsx";
import BpJson2Csv from "./ToolTabPanels/BpJson2Csv.tsx";
import BpUserTools from "./ToolTabPanels/BpUserTools.tsx";
import BpOrgTags from "./ToolTabPanels/BpOrgTags.tsx";
import CliDownloads from "./ToolTabPanels/CliDownloads.tsx";
// Custom hooks and functions

// const logger = new Logger("ScenarioLab", "INFO");

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const fileSets = [
  [
    "bpChangeLoader-linux-x64",
    "bpChangeLoader-macos-arm64",
    "bpChangeLoader-macos-x64",
    "bpChangeLoader-win-x64.exe",
  ], // Files for Change Loader tab
  [
    "bpinjector-linux-x64",
    "bpinjector-macos-arm64",
    "bpinjector-macos-x64",
    "bpinjector-win-x64.exe",
    "bpWebhookPoster-win-x64.exe",
    "bpWebhookPoster-linux-x64",
    "bpWebhookPoster-macos-arm64",
    "bpWebhookPoster-macos-x64",
  ], // Files for Data Poster tab
  [
    "bpClearIncidents-linux-x64",
    "bpClearIncidents-macos-arm64",
    "bpClearIncidents-macos-x64",
    "bpClearIncidents-win-x64.exe",
  ], // Files for Clear Incidents tab
  ["bpJson2Csv-linux", "bpJson2Csv-macos", "bpJson2Csv-win.exe"], // Files for JSON<>CSV tab
  [], // Files for Users tab
  [
    "bpAlertTags-linux-x64",
    "bpAlertTags-macos-arm64",
    "bpAlertTags-macos-x64",
    "bpAlertTags-win-x64.exe",
  ], // Files for Org Tags tab
];

export default function SeTools() {
  // Global state

  // Local state
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  //TODO Reset passwords
  return (
    <Paper id="SE Tools Page" className="RouteContainer">
      <Box
        sx={{
          position: "relative",
          width: "100%", // Full width of the screen
          mb: 2, // Margin at the bottom
        }}
      >
        {/* AppBar on the left */}
        <AppBar position="static" color="transparent">
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            aria-label="full width tabs example"
          >
            <Tab label="Change Loader" {...a11yProps(0)} />
            <Tab label="Data Poster" {...a11yProps(1)} />
            <Tab label="Clear Incidents" {...a11yProps(2)} />
            <Tab label="JSON<>CSV" {...a11yProps(3)} />
            <Tab label="Users" {...a11yProps(4)} />
            <Tab label="Org Tags" {...a11yProps(5)} />
            {/* <Tab label="Item Seven" {...a11yProps(6)} /> */}
          </Tabs>
        </AppBar>
        {/* CliDownloads on the right */}
        {fileSets[tabIndex].length && (
          <Box
            sx={{
              position: "absolute", // Use absolute positioning
              top: 10, // Adjust based on the height of the AppBar for alignment
              right: 0, // Align to the right edge
              zIndex: 1, // Ensure it is above other components
            }}
          >
            <CliDownloads fileNames={fileSets[tabIndex]} />
          </Box>
        )}
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <BpChangeLoader />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <DataPoster />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <BpClearIncidents />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <BpJson2Csv />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <BpUserTools />
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        <BpOrgTags />
      </TabPanel>
    </Paper>
  );
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
